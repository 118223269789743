import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import trim from 'lodash/trim'
import { Typography } from '@material-ui/core'

export const PHYSICAL_ATTRIBUTES = {
    AMOUNT: {
        name: 'cardAmount',
        label: 'Amount',
        descriptor: {
            isRequired: true,
            min: 10,
            max: 500,
            format: /^(?:[1-9][0-9]|1\d{2}|2\d{2}|3\d{2}|4\d{2}|500)$/,
            type: 'number',
        },
        conditionsForVisibilityOnCsv: {
            individualDeliveryStyle: [true, false],
            isVirtualProduct: [true, false],
        },
    },
    NAME_ON_CARD: {
        name: 'nameOnCard',
        label: 'Name On Card',
        descriptor: {
            isRequired: true,
            format: /^[a-zA-Z0-9-'. ]+$/,
            min: 2,
            max: 20,
            formatInvalidMessage: `Name On Card requires only alpha characters, hyphens, and apostrophes`,
        },
        infoTitle: 'Line 1 (appears on plastic card)',
        infoImage:
            'https://res.cloudinary.com/gift-card-granny/image/upload/f_auto/w_529,h_247/v1622813772/GCG/smb_assets/gcg_reward_card_example_p2ixdt',
        infoText: (
            <>
                <Typography>
                    This is a customizable Name that is printed on the card
                    above the Card Message field. This field does not have to be
                    a name.
                </Typography>
                <br />
                <Typography>
                    This field is required and can be up to 20 characters long.
                </Typography>
            </>
        ),
        conditionsForVisibilityOnCsv: {
            individualDeliveryStyle: [true, false],
            isVirtualProduct: [false],
        },
    },
    CARD_MESSAGE: {
        name: 'cardMessage',
        label: 'Card Message',
        descriptor: {
            isRequired: true,
            format: /^[a-zA-Z0-9-'. ]+$/,
            min: 2,
            max: 20,
            formatInvalidMessage: `Card Message requires only alpha characters, hyphens, and apostrophes`,
        },
        infoTitle: 'Line 2 (appears on plastic card)',
        infoImage:
            'https://res.cloudinary.com/gift-card-granny/image/upload/f_auto/w_529,h_247/v1622813772/GCG/smb_assets/gcg_reward_card_example_p2ixdt',
        infoText: (
            <>
                <Typography>
                    This is a customizable message that is printed on the card
                    below the Name On Card field.
                </Typography>
                <br />
                <Typography>
                    This field is required and can be up to 20 characters long.
                </Typography>
            </>
        ),
        conditionsForVisibilityOnCsv: {
            individualDeliveryStyle: [true, false],
            isVirtualProduct: [false],
        },
    },
    CARRIER_TO: {
        name: 'cardRecipientFullName',
        label: 'Carrier To',
        descriptor: {
            isRequired: true,
            format: /^[a-zA-Z0-9-'. ]+$/,
            min: 2,
            max: 20,
            formatInvalidMessage: `Carrier To requires only alpha characters, hyphens, and apostrophes`,
        },
        infoTitle: "Recipient's Name (appears on trifold paper)",
        infoImage:
            'https://res.cloudinary.com/gift-card-granny/image/upload/f_auto/w_529,h_484/v1622813476/GCG/smb_assets/gcg_carrier_example_fukmht',
        infoText: (
            <>
                <Typography>
                    The carrier is the sheet of paper that the card is affixed
                    to. It contains information on how to activate and use the
                    attached card.
                </Typography>
                <br />
                <Typography>
                    On the carrier, you can specify the “To:” field so the
                    recipient knows who it is for. In most cases, this will
                    match the Name On Card field, but it doesn’t have to -
                    you’re free to put an appropriate value here to meet your
                    needs.
                </Typography>
                <br />
                <Typography>
                    This field is required and can be up to 50 characters.
                </Typography>
            </>
        ),
        conditionsForVisibilityOnCsv: {
            individualDeliveryStyle: [true, false],
            isVirtualProduct: [false],
        },
    },
    CARRIER_FROM: {
        name: 'cardSenderFullName',
        label: 'Carrier From',
        descriptor: {
            isRequired: true,
            format: /^[a-zA-Z0-9-'. ]+$/,
            min: 2,
            max: 20,
            formatInvalidMessage: `Carrier From requires only alpha characters, hyphens, and apostrophes`,
        },
        infoTitle: "Sender's Name (appears on trifold paper)",
        infoImage:
            'https://res.cloudinary.com/gift-card-granny/image/upload/f_auto/w_529,h_484/v1622813476/GCG/smb_assets/gcg_carrier_example_fukmht',
        infoText: (
            <>
                <Typography>
                    The carrier is the sheet of paper that the card is affixed
                    to. It contains information on how to activate and use the
                    attached card.
                </Typography>
                <br />
                <Typography>
                    On the carrier, you can specify the “From:” field so the
                    recipient knows who the card is from. In most cases, this
                    will be your business name, but it doesn’t have to - you’re
                    free to put an appropriate value here to meet your needs.
                </Typography>
                <br />
                <Typography>
                    This field is required and can be up to 26 characters.
                </Typography>
            </>
        ),
        conditionsForVisibilityOnCsv: {
            individualDeliveryStyle: [true, false],
            isVirtualProduct: [false],
        },
    },
    CARRIER_MESSAGE: {
        name: 'carrierMessage',
        label: 'Carrier Message',
        descriptor: {
            isRequired: true,
            format: /^[a-zA-Z0-9-!?'\u2018\u2019,. \n]+$/,
            min: 2,
            max: 120,
            formatInvalidMessage: `Mailer Message requires only (!?-',.)`,
        },
        infoTitle: 'Custom Message (appears on trifold paper)',
        infoImage:
            'https://res.cloudinary.com/gift-card-granny/image/upload/f_auto/w_529,h_484/v1622813476/GCG/smb_assets/gcg_carrier_example_fukmht',
        infoText: (
            <>
                <Typography>
                    The carrier is the sheet of paper that the card is affixed
                    to. It contains information on how to activate and use the
                    attached card.
                </Typography>
                <br />
                <Typography>
                    On the carrier, you can specify the “Message:” field so the
                    recipient has context for why they are receiving the card.
                    In some cases this will be congratulatory, thank you for
                    business, appreciation, or another reward context, but it
                    doesn’t have to - you’re free to put an appropriate value
                    here to meet your needs.
                </Typography>
                <br />
                <Typography>
                    This field is required and can be up to 120 characters.
                </Typography>
            </>
        ),
        conditionsForVisibilityOnCsv: {
            individualDeliveryStyle: [true, false],
            isVirtualProduct: [false],
        },
    },
    SHIP_TO: {
        name: 'address',
        descriptor: {
            isRequired: true,
        },
        conditionsForVisibilityOnCardTable: {
            individualDeliveryStyle: [true],
            isVirtualProduct: [false],
        },
        fields: {
            RECIPIENT_NAME: {
                name: 'cardRecipientAddressRecipientName',
                label: 'Name of Recipient',
                descriptor: {
                    isRequired: true,
                    format: /^[-a-zA-Z0-9]+(\s+[-a-zA-Z0-9]+)*$/,

                    min: 2,
                    max: 20,
                    formatInvalidMessage: `Name Of Recipient requires only alpha characters, hyphens, and apostrophes`,
                },
            },
            ADDRESS_LINE_1: {
                name: 'cardRecipientAddressAddressLine1',
                label: 'Address Line 1',
                descriptor: {
                    isRequired: true,
                    format: /^[a-zA-Z0-9- .]+$/,
                    min: 2,
                    max: 20,
                    formatInvalidMessage: `Only alphanumeric characters and hyphens are allowed`,
                },
            },
            ADDRESS_LINE_2: {
                name: 'cardRecipientAddressAddressLine2',
                label: 'Address Line 2',
                descriptor: {
                    isRequired: false,
                    format: /^(|[a-zA-Z0-9- .]+)$/,
                    min: 0,
                    max: 20,
                    formatInvalidMessage: `Only alphanumeric characters and hyphens are allowed`,
                },
            },
            CITY: {
                name: 'cardRecipientAddressCity',
                label: 'City',
                descriptor: {
                    isRequired: true,
                    format: /^[a-zA-Z0-9- .]+$/,
                    min: 2,
                    max: 20,
                },
            },
            STATE: {
                name: 'cardRecipientAddressState',
                label: 'State',
                descriptor: {
                    type: 'state',
                    isRequired: true,
                    format: /^[A-Za-z]{2}$/,
                    min: 2,
                    max: 2,
                },
            },
            ZIP: {
                name: 'cardRecipientAddressZip',
                label: 'Zip',
                descriptor: {
                    isRequired: true,
                    format: /^\d{5}$/,
                    formatInvalidMessage: `Invalid zip code`,
                },
            },
        },
        conditionsForVisibilityOnCsv: {
            individualDeliveryStyle: [true],
            isVirtualProduct: [false],
        },
    },
}

export const DIGITAL_ATTRIBUTES = {
    AMOUNT: {
        name: 'cardAmount',
        label: 'Amount',
        descriptor: {
            isRequired: true,
            format: /^(?:[1-9][0-9]|1\d{2}|2\d{2}|3\d{2}|4\d{2}|500)$/,
            min: 10,
            max: 500,
            type: 'number',
        },
        conditionsForVisibilityOnCsv: {
            individualDeliveryStyle: [true, false],
            isVirtualProduct: [true, false],
        },
    },

    FULL_NAME: {
        name: 'cardRecipientFullName',
        label: 'Sender To',
        descriptor: {
            isRequired: true,
            format: /^[a-zA-Z0-9-'. ]+$/,
            min: 2,
            max: 60,
            formatInvalidMessage: `Send To requires only alpha characters, hyphens, and apostrophes`,
        },
        infoTitle: 'Card Recipient Full Name',
        infoImage:
            'https://res.cloudinary.com/gift-card-granny/image/upload/f_auto/w_529,h_398/v1622816742/GCG/smb_assets/gcg_example_delivery_email_ouuyld',
        infoText: (
            <>
                <Typography>
                    This is the full name of the recipient of your virtual/egift
                    card.
                </Typography>
                <br />
                <Typography>
                    Typically this is a person's full name, but if you want your
                    card to be anonymous, you can use phrases such as "Good" and
                    "Job" or "Thank" and "You" as the first name.
                </Typography>
            </>
        ),
        conditionsForVisibilityOnCsv: {
            individualDeliveryStyle: [true, false],
            isVirtualProduct: [true],
        },
    },

    EMAIL_MESSAGE: {
        name: 'carrierMessage',
        label: 'Email Message',
        descriptor: {
            isRequired: true,
            format: /^[a-zA-Z0-9-!?'\u2018\u2019,. \n]+$/,
            min: 2,
            max: 120,
            formatInvalidMessage: `Email Message requires only (!?-',.)`,
        },
        infoTitle: 'Email message',
        infoImage:
            'https://res.cloudinary.com/gift-card-granny/image/upload/f_auto/w_529,h_398/v1622816742/GCG/smb_assets/gcg_example_delivery_email_ouuyld',
        infoText: (
            <>
                <Typography>
                    When we deliver the virtual/egift card to the recipient, the
                    email will have a custom message field.
                </Typography>
                <br />
                <Typography>
                    You can use this to provide more information or longer
                    messaging to the recipient.
                </Typography>
                <br />
                <Typography>
                    This field is required and can be up to 150 characters long.
                </Typography>
            </>
        ),
        conditionsForVisibilityOnCsv: {
            individualDeliveryStyle: [true, false],
            isVirtualProduct: [true],
        },
    },
    EMAIL_ADDRESS: {
        name: 'emailAddress',
        label: 'Email Address',
        descriptor: {
            isRequired: true,
            format: /(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)/,
            min: 5,
            max: 254,
            type: 'email',
        },
        conditionsForVisibilityOnCsv: {
            individualDeliveryStyle: [true],
            isVirtualProduct: [true],
        },
    },
    SENDER_NAME: {
        name: 'cardSenderFullName',
        label: 'Carrier From',
        descriptor: {
            isRequired: true,
            format: /^[a-zA-Z0-9-'. ]+$/,
            min: 2,
            max: 20,
            formatInvalidMessage: `Sender Name requires only alpha characters, hyphens, and apostrophes`,
        },
        conditionsForVisibilityOnCsv: {
            individualDeliveryStyle: [true, false],
            isVirtualProduct: [true],
        },
    },
}

export const validLength = (attribute, value) => {
    const { min, max } = attribute.descriptor
    let isValidLength
    const valueString = trim(String(value))
    isValidLength = valueString.length >= min && valueString.length <= max
    return isValidLength
}

export const validFormat = (attribute, value) => {
    const { format } = attribute.descriptor
    let isValidFormat = true
    if (format) {
        isValidFormat = String(value).match(format)
    }
    return isValidFormat
}

export const isValid = (attribute, params, allowEmpty = true) => {
    const { value } = params

    // Treat unset values as OK until entered
    if (allowEmpty === true && isEmpty(value)) {
        return true
    }

    // If the field isn't required and it's not set, that's OK
    if (
        isEmpty(trim(value)) &&
        get(attribute, 'descriptor.isRequired', null) === false
    ) {
        return true
    }

    if (attribute && value) {
        return validLength(attribute, value) && validFormat(attribute, value)
    }
}

export const isValueValid = (selectedProduct, params) => {
    const { product } = selectedProduct

    const value = parseFloat(params.value)
    const minDenomination = parseFloat(product.min_load_value)
    const maxDenomination = parseFloat(product.max_load_value)

    return value >= minDenomination && value <= maxDenomination
}

export const getValidationMessage = (attribute, params) => {
    const { value } = params
    const { min, max } = attribute.descriptor

    if (
        isEmpty(trim(value)) &&
        get(attribute, 'descriptor.isRequired', null) === true
    ) {
        return 'This field is required. Please enter a value.'
    }

    if (!validLength(attribute, value)) {
        return `Please enter a value between ${min} and ${max} characters.`
    }

    if (!validFormat(attribute, value)) {
        return 'Please enter a valid format.'
    }
}
